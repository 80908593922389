/**
 * * 轮播管理页面
 */

import service from '@/utils/request'
import { API_PROXY } from './commonProxy'
import { CarouselGroupType } from '@/enums'

/**
 * @description 获取分组列表
 * @param {CarouselGroupType} params.groupType 分组类型
 * @param {String} params.name 分组名称
 * @type {BiGroupParam}
 */
type BiGroupParam = {
  groupType?: CarouselGroupType
  name?: string
}
export const getBiGroup = (params: BiGroupParam) => {
  return service.request({
    url: `${API_PROXY}/api/group`,
    method: 'get',
    params,
  })
}

/**
 * @description 新增分组
 * @param data
 */
type CreateBiGroup = {
  groupType: CarouselGroupType
  name?: string
  parentId?: string
  returnId?: string
  updateId?: string
}
export const createBiGroup = (data: CreateBiGroup) => {
  return service.request({
    url: `${API_PROXY}/api/group`,
    method: 'post',
    data,
  })
}

/**
 * @description 更新分组
 * @param data
 */
type UpdateBiGroup = Omit<CreateBiGroup, 'returnId'>
export const updateBiGroup = (groupId: string, data: UpdateBiGroup) => {
  return service.request({
    url: `${API_PROXY}/api/group/${groupId}`,
    method: 'put',
    data,
  })
}

/**
 * @description 删除分组
 * @param {String} groupId
 */
export const deleteBiGroup = (groupId: string) => {
  return service.request({
    url: `${API_PROXY}/api/group/${groupId}`,
    method: 'delete',
  })
}

/**
 * @description 获取BI列表
 * @type {GetBiList}
 */
type GetBiList = {
  groupId: string
  // 看板名称
  name?: string
  // 页面名称
  pageName?: string
  pageIndex?: number
  pageSize?: number
}
export const getBiList = (data: GetBiList) => {
  return service.request({
    url: `${API_PROXY}/api/boardbi/list`,
    method: 'post',
    data,
  })
}

// 获取发布历史列表
export const getReleaseBiList = (boardId: string) => {
  return service.request({
    url: `${API_PROXY}/api/boardbiVersion/${boardId}`,
    method: 'get',
  })
}

// 发布版本
export const releaseBiList = (boardId: string, data) => {
  return service.request({
    url: `${API_PROXY}/api/boardbiVersion/${boardId}`,
    method: 'post',
    data
  })
}

/**
 * @description 删除boardBiList
 * @param {Number[]} ids
 */
type DeleteBiItem = {
  ids: string[]
}
export const deleteBiListItem = (data: DeleteBiItem) => {
  return service.request({
    url: `${API_PROXY}/api/boardbi/del`,
    method: 'post',
    data,
  })
}

/**
 * @description 新增或者编辑看板
 */
export type CreateOrUpdateBiItem = {
  groupId: string
  id?: string
  name: string
  pageName: string
}
export const createOrEditBiListItem = (data: CreateOrUpdateBiItem) => {
  return service.request({
    url: `${API_PROXY}/api/boardbi/saveOrUpdate`,
    method: 'post',
    data,
  })
}

/**
 * @description 查询boardBi详情中的列表
 */
export const getBoardItemList = (boardBiId: string) => {
  return service.request({
    url: `${API_PROXY}/api/boardbiItem/${boardBiId}`,
    method: 'get'
  })
}

/**
 * @description 删除boardBi详情中的页面
 */
export const deleteBoardItem = (boardBiId: string) => {
  return service.request({
    url: `${API_PROXY}/api/boardbiItem/${boardBiId}`,
    method: 'delete'
  })
}

/**
 * @description 排序boardBi详情中的页面
 */
export const sortBoardItem = (data) => {
  return service.request({
    url: `${API_PROXY}/api/boardbiItem/serialNumber`,
    method: 'put',
    data
  })
}

/**
 * @description 查询全局boardBi配置信息
 */
export const getGlobalBoardInfo = (boardBiId: string) => {
  return service.request({
    url: `${API_PROXY}/api/boardbi/detail/${boardBiId}`,
    method: 'get'
  })
}

/**
 * @description 更新全局boardBi配置信息
 */
export const updateGlobalBoardInfo = (data) => {
  return service.request({
    url: `${API_PROXY}/api/boardbi`,
    method: 'post',
    data
  })
}

/**
 * @description 查询详情中单个页面的配置信息
 */
export const getBoardItemInfo = (boardBiId: string) => {
  return service.request({
    url: `${API_PROXY}/api/boardbiItem/details/${boardBiId}`,
    method: 'get'
  })
}

/**
 * @description 新增详情中单个页面
 */
export const createBoardItemInfo = (data) => {
  return service.request({
    url: `${API_PROXY}/api/boardbiItem`,
    method: 'post',
    data
  })
}

/**
 * @description 更新详情中单个页面的配置信息
 */
export const updateBoardItemInfo = (data) => {
  return service.request({
    url: `${API_PROXY}/api/boardbiItem/details`,
    method: 'post',
    data
  })
}


/**
 * @description 获取可导入的页面列表
 */
export const getImportPageList = params => {
  return service.request({
    url: `${API_PROXY}/api/manager/webPageTree`,
    method: 'get',
    params
  })
}

